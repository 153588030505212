@use "../mixin/variables" as *;
@use "../mixin/breakpoint" as *;
//フォントの定義
%font {
  font-size: 1.6rem;
  font-weight: 500;
  font-family: "Noto Sans JP", "源ノ角ゴシック", "Yu Gothic Medium","游ゴシック Medium","游ゴシック",YuGothic,YuGothicM,"ヒラギノ角ゴ Pro W3","Hiragino Kaku Gothic Pro","メイリオ",Meiryo,"ＭＳ Ｐゴシック",sans-serif;
  color: $font-color-base;
  line-height: 2;
  @include mq(sp) {
    font-size: 1.4rem;
    line-height: 1.71428;
  }
}

@mixin font-lato {
  font-family: 'Lato', sans-serif;
}